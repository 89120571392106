import { gql } from "graphql-request";
import { DateTime } from "luxon";
import { TopBanners_Query } from "@graphql/types";
import { FesHeroProps } from "@newt/ui";
import { MarketingService } from "@utils/marketing";

const GET_HERO_IMAGES_BANNER_QUERY = gql`
  fragment GetFesHeroImages_Banner on Banner {
    name
    status
    validFrom
    validTo
    url
    topBannerImagesForMobile {
      backgroundImage {
        url(size: MD)
      }
      foregroundImage {
        url(size: MD)
      }
    }
    topBannerImagesForPC {
      backgroundImage {
        url(size: ORIGINAL)
      }
      foregroundImage {
        url(size: ORIGINAL)
      }
    }
  }
`;

export const getFesHeroImages = (
  banners?: TopBanners_Query["fesTopBanners"]
): null | FesHeroProps => {
  if (!banners || banners?.edges.length === 0) {
    return null;
  }

  const topBanner = banners.edges[0].node;
  const today = DateTime.now();
  const validFrom = DateTime.fromISO(topBanner.validFrom);
  const validTo = DateTime.fromISO(topBanner.validTo);

  if (today < validFrom || today > validTo) {
    return null;
  }

  const handleFesClick = () => {
    MarketingService.logEvent.clickLink({
      link_type: "top_banner",
      link_url: topBanner.url,
    });
    window.open(topBanner.url, "_blank");
  };

  return {
    bgImgPcSrc: topBanner.topBannerImagesForPC?.backgroundImage.url || "",
    bgImgSpSrc: topBanner.topBannerImagesForMobile?.backgroundImage.url || "",
    titleImgPcSrc: topBanner.topBannerImagesForPC?.foregroundImage?.url || "",
    titleImgSpSrc:
      topBanner.topBannerImagesForMobile?.foregroundImage?.url || "",
    alt: topBanner.name || "",
    onClick: handleFesClick,
  };
};
